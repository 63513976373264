@use "styles/colours";

.loading-screen {
  // background-color: $blackOpacity;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;
  width: auto;
  height: auto;
  overflow-y: auto;

  &__animation {
    position: absolute;
    align-self: center;
    justify-content: center;
    max-width: 496px;
    z-index: 9;
  }
  &__text {
    width: 100%;
    height: 100%;
    z-index: 9;
  }

  &::after {
    content: "";
    position: fixed;
    background-color: colours.$blackOpacity;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
}
